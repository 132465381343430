import * as React from 'react';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';

export default function MyRating({name,myvalue}) {

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    setValue(myvalue);
  }, [myvalue]);



  return (
    <Box
      sx={{
        '& > legend': { mt: 2 },
        marginBottom:'-5px'
        
      }}
    >

        <Rating
            name={name}
            value={value} 
            readOnly
            

            
        />
   

    </Box>
  );
}

