import React from 'react'
import Grid from '@mui/material/Grid'
import open_in_new from '../assets/images/open_in_new.png'
import { useEffect } from 'react'
import Slider from '@mui/material/Slider'
import { useTheme } from '@mui/material/styles'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import PlaceSpecific from './Placespecific'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Place = ({name,image,description,places,apikey,ischecked,chosenplace, setChosenplace}) => {

    const [position,setPosition]=useState(0)
    const issmall=useMediaQuery('(max-width:600px)')
    
   



    const moveLeft=()=>{

        if(position>=800){
            return

        }
     
        setPosition(issmall? position+70:position+100)
    }

    const moveRight=()=>{

        if(position<=-800){
            return
        }


     
        setPosition(issmall? position-70:position-100)
    }

 
    if(ischecked){ 

    return (
      
        <>
     <Button variant="text" onClick={moveRight}>
                <ArrowForwardIosIcon/>
            </Button>
        <Box
          sx={{
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
      
            height: '550px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              transition: 'transform 0.5s ease-in-out',
              transform: `translateX(${position}px)`,
            }}
          >

            {
              places.map((place, index) => (
                <PlaceSpecific name={place.name} rating={place.rating} types={place.types} 
                icon={place.icon} address={place.vicinity} apikey={apikey} latitude={place.geometry.location.lat} longitude={place.geometry.location.lng}
                 chosenplace={chosenplace} setChosenplace={setChosenplace} placefull={place} ischecked={ischecked}
                />
              ))
            }

    
          </Box>
          <Box sx={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
           
           
          </Box>
        </Box>
        <Button variant="text" onClick={moveLeft}>
              <ArrowBackIosNewIcon/>
            </Button>
        </>
      );
    }
    else{
      return  (
      <>
      <Button variant="text" style={{opacity:0.5}}>
                 <ArrowForwardIosIcon/>
             </Button>
         <Box
           sx={{
             overflow: 'hidden',
             position: 'relative',
             display: 'flex',
             flexDirection: 'column',
             alignItems: 'center',
             height: '550px',
           }}
         >
           <Box
             sx={{
               display: 'flex',
               transition: 'transform 0.5s ease-in-out',
               transform: `translateX(${position}px)`,
             }}
           >
 
             {
               places.map((place, index) => (
                 <PlaceSpecific name={place.name} image={place.photos[0].photo_reference} rating={place.rating} types={place.types} 
                 icon={place.icon} address={place.vicinity} apikey={apikey} latitude={place.geometry.location.lat} longitude={place.geometry.location.lng}
                  chosenplace={chosenplace} setChosenplace={setChosenplace} placefull={place} ischecked={ischecked}
                 />
               ))
             }
 
     
           </Box>
           <Box sx={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
            
            
           </Box>
         </Box>
         <Button variant="text" style={{opacity:0.5}}>
         <ArrowBackIosNewIcon/>
             </Button>
         </>
      )

    }
    };
    





export default Place