const content_writing=`
בניית האתר עצמו היא רק חלק מהעבודה. על מנת להביא לקוחות חדשים ולהגדיל את החשיפה, קריטי לכתוב תוכן איכותי שמתאים לקהל היעד של האתר. כחלק מהשירות, אספק תוכן לאתר, מבוסס המלצות בינה מלאכותית, אשר יתן לאתר שלך יתרון משמעותי על אתרים אחרים בקטגוריה שלך.
`;

const content_writing_small=`כתיבת תוכן איכותי מבוסס בינה מלאכותית שמתאים לקהל היעד`

const website_building=`
בניית אתר מודרני ומתקדם בטכנולוגיות החדישות ביותר. לא מדובר על אתר זול שניתן לבנות בפלטפורמות מסוימות בכמה דקות, אלא על אתר מותאם אישית למחשבים ולטלפונים ניידים, עם עיצוב מודרני ומתקדם, ופונקציונליות שמאפשרת תקשורת עם הלקוח, שמירת נתונים, בניית מערך לקוחות ועוד
`

const website_building_small=`בניית אתר מודרני ומתקדם בטכנולוגיות החדישות ביותר (לא wix)`;


const organic_promotion=`
חלק משמעותי מהחבילה הינו ניהול הקידום האורגני של האתר. בכך אתם מבטיחים שהאתר שלכם לא רק יראה מעולה, אלא גם ימצא בקלות בגוגל. בניית אתר עם קידום אורגני מאפשרת לי להתאים את העיצוב והתכנים לדרישות גוגל מהתחלה, ובכך לשפר את הסיכויים שלכם להופיע בדף הראשון.`

const organic_promotion_small=` ניהול קידום אורגני ומותאם לגוגל,ללא תשלום נוסף`


const maintainece=`
שירות התחזוקה שלי מבטיח שלא תשארו לבד בעולם הדיגיטלי. אני מתחייב להיות לצידכם לאורך כל הדרך, מהיום שהאתר שלכם עולה לאוויר ובכל עת שתזדקקו לעזרה או תיקונים. גם אם אתם לא "מביני טכנולוגיה", אין צורך לדאוג. אני כאן להסביר, לתקן ולעדכן, כך שתוכלו להרגיש בטוחים ונינוחים עם הנוכחות הדיגיטלית שלכם.`

const maintainece_small=`שירות תחזוקה ותמיכה מותאם אישית`


export {content_writing,website_building,organic_promotion,maintainece,content_writing_small,website_building_small,organic_promotion_small,maintainece_small}